import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <StaticImage src={'../../assets/images/logo.png'}
                                        loading="eager" 
                                        alt="Tafoma's logo."
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"
                                        // imgStyle="logo"
                                        className="logo"
                                        fadeIn={false}
                                        />
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item">
                                        <Link
                                            to="/about"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            About
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/features"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Features
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/pricing"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Pricing
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/blog"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Blog
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/industry"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Industries
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/faq"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            FAQ
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                            to="/contact"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Contact
                                                </Link>
                                    </li>

                                </ul>


                                <div className="others-option d-flex align-items-center">
                                <a
                                            href="https://app.tafoma.com"
                                            // activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn white-textt"
                                        >
                                    <div className="option-item">
                                        
                                            Sign In <span></span>
                                        
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
