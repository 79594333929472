import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Tafoma - One Tool for All Your Collaboration Needs</title>
                <meta name="description" content="Tafoma - One Tool for All Your Collaboration Needs" />
                <meta name="og:title" property="og:title" content="Tafoma - One Tool for All Your Collaboration Needs"></meta>
                <meta name="twitter:card" content="Tafoma - One Tool for All Your Collaboration Needs"></meta>
                {/* <link rel="canonical" href="https://rewy-react.envytheme.com/"></link> */}
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
